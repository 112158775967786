import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NetworkService } from './network.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginServiceService {
  checkSessionUrl:string=this.network.urlInitial+"checkSession.php";
  constructor(private http: HttpClient, private network: NetworkService, private router: Router) {}
  currentexpiry:any;
  isLogin:boolean=false;

  sessionControl():void{
    if(sessionStorage.getItem('api')!=null){
      var now = new Date().getTime();
      const expiry = sessionStorage.getItem('expiry');
      const session = sessionStorage.getItem('api');
      this.currentexpiry = expiry !== null ? JSON.parse(expiry) : '{}';
      if(now<parseInt(this.currentexpiry)){
        var sendData={'session': session, 'userId': sessionStorage.getItem('email')};
        this.network.postdata(this.checkSessionUrl,sendData).subscribe(data => {
          if(data==true){            
            localStorage.setItem('isLogin','true');
          }else{
            localStorage.removeItem('isLogin');
          }
        })
      }else{
        this.router.navigate(['']);
      }
    }else{
      this.router.navigate(['']);
    }
  }
  logoutSession():any{
    if(sessionStorage.getItem('api')!=null){
      var now = new Date().getTime();
      const expiry = sessionStorage.getItem('expiry');
      const session = sessionStorage.getItem('api');
      var sendData={'session': session, 'userId': sessionStorage.getItem('email')};
      return sendData;
    }
  }
}
