import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { ForgotComponent } from './forgot/forgot.component';
import { CreateInvoiceComponent } from './create-invoice/create-invoice.component';
import { ClientListComponent } from './client-list/client-list.component';
import { ProductsComponent } from './products/products.component';
import { ServicesComponent } from './services/services.component';
import { AdminsComponent } from './admins/admins.component';
import { SettingsComponent } from './settings/settings.component';
import { HelpComponent } from './help/help.component';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { CheckListComponent } from './check-list/check-list.component';
import { DisplayComponent } from './display/display.component';
import { EditInvoiceComponent } from './edit-invoice/edit-invoice.component';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  {path:'', component:LoginComponent},
  {path:'forgot', component:ForgotComponent},
  {path:'client', component: ClientListComponent, canActivate: [AuthGuard]},
  {path:'products', component: ProductsComponent, canActivate: [AuthGuard]},
  {path:'services', component: ServicesComponent, canActivate: [AuthGuard]},
  {path:'invoice', component: InvoiceListComponent, canActivate: [AuthGuard]},
  {path:'admins', component: AdminsComponent, canActivate: [AuthGuard]},
  {path:'settings', component: SettingsComponent, canActivate: [AuthGuard]},
  {path:'help', component: HelpComponent, canActivate: [AuthGuard]},
  {path:'checklist/:id', component: CheckListComponent, canActivate: [AuthGuard]},
  {path:'create', component: CreateInvoiceComponent, canActivate: [AuthGuard]},
  {path:'generate/:id', component: CreateInvoiceComponent, canActivate: [AuthGuard]},
  {path:'edit/:id', component: EditInvoiceComponent, canActivate: [AuthGuard]},
  {path:'display/:id', component: DisplayComponent, canActivate: [AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
