<div class="app" id="app">
  <div class="padding">
    <div class="navbar">
      <div class="pull-center">
        <!-- brand -->
        <a href="index.html" class="navbar-brand">
          <div data-ui-include="./assets/images/logo.svg"></div>
          <img src="./assets/images/logo_black.png" alt=".">
          <span class="hidden-folded inline" class="hide">Etlite</span>
        </a>
        <!-- / brand -->
      </div>
    </div>
  </div>
  <div class="b-t">
    <div class="center-block w-xxl w-auto-xs p-y-md text-center">
      <div class="p-a-md">
        <div>
          <h4>Forgot your password?</h4>
          <p class="text-muted m-y">
            Enter your email below and we will send you instructions on how to change your password.
          </p>
        </div>
        <form name="form" [formGroup]="loginData" (ngSubmit)="forgotPassword()">
          <div class="form-group">
            <input type="email" placeholder="Email" class="form-control" required formControlName="username">
          </div>
          <button type="submit" class="btn black btn-block p-x-md">Send</button>
        </form>
        <div class="p-y-lg">
          Return to
          <a href="" class="text-primary _600">Sign in</a>
        </div>
      </div>
    </div>
  </div>
</div>
