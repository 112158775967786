<div class="app" id="app">
    <div id="aside" class="app-aside fade nav-dropdown black">
      <app-footer class="navside dk" data-layout="column"></app-footer>
    </div>
    <!-- / -->
    <!-- content -->
    <div id="content" class="app-content box-shadow-z2 bg pjax-container" role="main">
      <div class="app-header white bg b-b">
        <div class="navbar" data-pjax>
          <a data-toggle="modal" data-target="#aside" class="navbar-item pull-left hidden-lg-up p-r m-a-0">
            <i class="ion-navicon"></i>
          </a>
          <div class="navbar-item pull-left h5" id="pageTitle">Invoice List</div>
        </div>
      </div>
      <div class="app-footer white bg p-a b-t">
        <div class="pull-right text-sm text-muted">Version 1.0.1</div>
        <span class="text-sm text-muted">&copy; Copyright.</span>
      </div>
      <div class="app-body">
        <!-- only need a height for layout 4 -->
        <div class="box container">
          <div class="row">
            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered" style="width:100%">
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Client Name</th>
                  <th>Phone Number</th>
                  <th>Email Id</th>
                  <th>Address</th>
                  <th>GST No</th>
                  <th>External Sheets</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of InvoiceList; let i=index;">
                  <td>{{i+1}}</td>
                  <td>{{item.name}}</td>
                  <td>{{item.phoneNo}}</td>
                  <td>{{item.email}}</td>
                  <td>{{item.address}}</td>
                  <td>{{item.gst}}</td>
                  <td>
                    <button [routerLink]="['/checklist', item.slNo]" class="btn info" style="margin-left: 5px; margin-top: 2px;"><i class="fa fa-check-square-o"
                      data-toggle="tooltip" data-placement="top" title="Check List"></i></button>  
                    <button class="btn info" style="margin-left: 5px; margin-top: 2px;" (click)="getDriveUrl(item.slNo)"><i class="fa fa-table"
                      data-toggle="tooltip" data-placement="top" title="View Excel"></i></button>  
                    <button (click)="getInvoice(item.slNo)" class="btn info" style="margin-left: 5px; margin-top: 2px;"><i class="fa fa-file-text"
                      data-toggle="tooltip" data-placement="top" title="View Doc"></i></button>
                      <button (click)="viewInvoice(item.slNo)" class="btn info" style="margin-left: 5px; margin-top: 2px;"><i class="fa fa-eye"
                        data-toggle="tooltip" data-placement="top" title="View Doc"></i></button>  
                    <button (click)="delete(item.slNo)" class="btn danger" style="margin-left: 5px;"><i class="fa fa-trash"
                      data-toggle="tooltip" data-placement="top" title="Delete"></i></button>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Sl.No</th>
                  <th>Client Name</th>
                  <th>Phone Number</th>
                  <th>Email Id</th>
                  <th>Address</th>
                  <th>GST No</th>
                  <th>External Sheets</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- / .modal -->