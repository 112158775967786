import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormArray, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { NetworkService } from './../network.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import Swal from 'sweetalert2';

declare var $: any;
@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.css']
})
export class ClientListComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  invoiceList:any=[];
  constructor(private route: ActivatedRoute, private router: Router, public fb: FormBuilder, private network: NetworkService, private http: HttpClient, private sanitizer:DomSanitizer) { }
  getClientListUrl:string=this.network.urlInitial+"clientGet.php";
  getClientInvoiceUrl:string=this.network.urlInitial+"getClientInvoiceId.php";
  getUserInvoiceUrl:string=this.network.urlInitial+"getUserInvoice.php";
  deleteUrl:string=this.network.urlInitial+"deleteClient.php";

  clientList:any=[];
  id:any=123;

  ngOnInit(): void {
    this.getClientList();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 8
    };
  }
  viewChecklist(){
  }
  getClientList(){    
    var apiVal = {'api': sessionStorage.getItem('api')};   
    this.network.postdata(this.getClientListUrl,apiVal).subscribe(data => {      
      this.clientList=data;
      this.dtTrigger.next();      
    })
  }
  viewInvoice(id:any){
    var api=sessionStorage.getItem('api');
    var url="/pdf/invoice/?key="+api+"&id="+id;
    window.open(url);
  }
  
  getInvoice(id:any){
    var url="#/edit/"+id;
    window.open(url, "_blank");
  }
  getList(item:any){    
    var apiVal = {'id': item};   
    this.network.postdata(this.getUserInvoiceUrl,apiVal).subscribe(data => {      
      this.invoiceList=data;      
    })    
  }
  deleteList(slNo:any){
    Swal.fire({
      title: 'Are you sure?',text: "You won't be able to revert this!",icon: 'warning',
      showCancelButton: true,confirmButtonColor: '#3085d6',cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        var apiVal = {'api': sessionStorage.getItem('api'), 'id':slNo};
        this.network.postdata(this.deleteUrl,apiVal).subscribe(data => {
          if(data==false){
            Swal.fire('Sorry!','You dont have enough privilage','error')
          }
          else{
            Swal.fire('Deleted!','Your file has been deleted.','success')
          }
          this.getClientList();
          window.location.reload();
        })
      }
    })
  }
}
