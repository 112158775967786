<div class="app" id="app">
  <div id="aside" class="app-aside fade nav-dropdown black">
    <app-footer class="navside dk" data-layout="column"></app-footer>
  </div>
  <!-- / -->
  <!-- content -->
  <div id="content" class="app-content box-shadow-z2 bg pjax-container" role="main">
    <div class="app-header white bg b-b">
      <div class="navbar" data-pjax>
        <a data-toggle="modal" data-target="#aside" class="navbar-item pull-left hidden-lg-up p-r m-a-0">
          <i class="ion-navicon"></i>
        </a>
        <div class="navbar-item pull-left h5" id="pageTitle">Checklist</div>
      </div>
    </div>
    <div class="app-footer white bg p-a b-t">
      <div class="pull-right text-sm text-muted">Version 1.0.1</div>
      <span class="text-sm text-muted">&copy; Copyright.</span>
    </div>
    <div class="app-body">
      <!-- only need a height for layout 4 -->
      <div class="box container">
        <div class="row">
          <button class="btn btn-fw info" style="margin: 20px;" data-toggle="modal" data-target="#m-md"><i
              class="fa fa-plus" style="padding: 2px 6px;"></i>Add Checklist</button>
        </div>
        <div class="row">
          <table id="example" class="table table-striped table-bordered" style="width:100%">
            <thead>
              <tr>
                <th>SL.NO</th>
                <th>ITEMS</th>
                <th>QUANTITY</th>
                <th>QUANTITY TAKEN</th>
                <th>REMARKS</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of checklist; let i=index;">
                <td>{{i+1}}</td>
                <td>{{item.productId}}</td>
                <td>{{item.custom}}</td>
                <td>{{item.price}}</td>
                <td>{{item.quantity}}</td>
                <td>
                  <button (click)="delete(item.slNo)" class="btn danger" style="margin-left: 5px;"><i
                      class="fa fa-trash" data-toggle="tooltip" data-placement="top" title="Delete"></i></button>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>SL.NO</th>
                <th>ITEMS</th>
                <th>QUANTITY</th>
                <th>QUANTITY TAKEN</th>
                <th>REMARKS</th>
                <th>Action</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- large modal -->
<div id="m-md" class="modal" data-backdrop="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Add Checklist</h5>
      </div>
      <form [formGroup]="checkData" (ngSubmit)="addChecklist()">
        <div class="modal-body text-center p-lg">
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">ITEM</label>
            <div class="col-sm-10">
              <div class="ng-autocomplete">
                <ng-autocomplete [data]="productsNServices" [searchKeyword]="keyword"
                  placeholder="Enter Product or Service" (selected)='selectEvent($event)'
                  (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)'
                  historyIdentifier="productsNServices" [itemTemplate]="itemTemplate"
                  [notFoundTemplate]="notFoundTemplate">
                </ng-autocomplete>

                <ng-template #itemTemplate let-item>
                  <a [innerHTML]="item.name"></a>
                </ng-template>

                <ng-template #notFoundTemplate let-notFound>
                  <div [innerHTML]="notFound"></div>
                </ng-template>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">QUANTITY:</label>
            <div class="col-sm-10">
              <input type="text" class="form-control" formControlName="custom">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">QUANTITY TAKEN:</label>
            <div class="col-sm-10">
              <input type="text" class="form-control" formControlName="quantity">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">REMARKS:</label>
            <div class="col-sm-10">
              <input type="text" class="form-control" formControlName="price">
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn danger p-x-md" #cancelModel data-dismiss="modal">Cancel</button>
          <button type="submit" class="btn success p-x-md m-l">Add To Checklist </button>
        </div>
      </form>
    </div><!-- /.modal-content -->
  </div>
</div>
<!-- / .modal -->
