import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

import { FormGroup, FormArray, FormControl, Validators, FormBuilder } from '@angular/forms';
import { NetworkService } from './../network.service';
import Swal from 'sweetalert2';
import { Subject } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-admins',
  templateUrl: './admins.component.html',
  styleUrls: ['./admins.component.css']
})
export class AdminsComponent implements OnInit {
  @ViewChild('closeadmin') closeadmin:any;
  @ViewChild('closePass') closePass:any;

  adminList:any=[];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  uploadUrl:string=this.network.urlInitial+"getAdmins.php";
  addAdminUrl:string=this.network.urlInitial+"addadmin.php";
  usernameUrl:string=this.network.urlInitial+"checkUsername.php";
  deleteAdminUrl:string=this.network.urlInitial+"deleteAdmin.php";
  updateAdminUrl:string=this.network.urlInitial+"updateAdmin.php";
  getUserIdUrl:string=this.network.urlInitial+"geymyId.php";
  passwordChangeUrl:string=this.network.urlInitial+"changePassword.php";
  constructor(public fb: FormBuilder, private network: NetworkService) { }
  password:any="";
  repeatPass:any="";
  usrName:any="";
  checkEdit:boolean=true;
  buttonValue:any="Add User";
  checkRe:boolean=false;
  checkUser:boolean=false;
  checkPass:boolean=true;
  myid:any='';
  changeUser:any=''
  finalData = this.fb.group({
    name:'', username:'', password:'', repeatpassword:'', email:'', type:'', api:'', id:''
  })
  changePassword = this.fb.group({
    currentPass:'', newpass:'', repeatPass:'', api:'', id:''
  })
  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 8
    };
    this.getAdmins();
  }
  getAdmins(){
    var apiVal = {'api': sessionStorage.getItem('api')};
    this.network.postdata(this.getUserIdUrl,apiVal).subscribe(data =>{
      this.myid=data;    
    })   
    this.network.postdata(this.uploadUrl,apiVal).subscribe(data => {
      this.adminList=data;
      this.dtTrigger.next();      
    })
  }
  modelLaunch(){
    this.checkEdit=true;
    this.buttonValue="Add User";
    this.finalData.reset();
  }
  addAdmin(){
    if(this.checkRe && this.checkUser){

    }
    else{
      if(!this.checkEdit){
        this.network.postdata(this.updateAdminUrl, this.finalData.value).subscribe(data => {
          this.getAdmins();
          this.closeadmin.nativeElement.click();
        })    
      }else{        
        var apiVal = sessionStorage.getItem('api');
        this.finalData.patchValue({
          api: apiVal
        });
        this.network.postdata(this.addAdminUrl,this.finalData.value).subscribe(data => {
        this.getAdmins();
        this.closeadmin.nativeElement.click();
      });
      }
    }
  }
  checkrepeat(){
   if(this.password==this.repeatPass){
    this.checkRe=false;
   }else{
    this.checkRe=true;
   }
  }
  checkUsername(){
    this.adminList.forEach((value:any) => {
      if(value['username']==this.usrName){
        this.checkUser= true;
      }else{
        this.checkUser=false;
      }
  });
  }
  changePass(i:any,j:any){
    this.changePassword.patchValue({
      currentPass:""
    })
    this.changeUser=j;
    this.changePassword.reset();    
    if(this.myid==0){
      this.checkPass=false;
    }else if(i==this.myid){
      this.checkPass=false;
    }else if(i>this.myid){
      this.checkPass=false;
    }else{
      this.checkPass=true;
    }
  }
  postpassChange(){
    var apiVal = sessionStorage.getItem('api');
    this.changePassword.patchValue({
      api: apiVal,
      id:this.changeUser
    });
    if(this.changePassword.value['newpass']==this.changePassword.value['repeatPass'] && this.changePassword.value['newpass']!=null){
      this.checkRe=false;
      this.network.postdata(this.passwordChangeUrl, this.changePassword.value).subscribe(data => {        
        if(data=="x0123"){
          Swal.fire(
            'Wrong Password!',
            'Nice Try.',
            'error'
          )
        }
        else if(data==true){
          Swal.fire(
            'Change Password!',
            'Password has changed sucessfully.',
            'success'
          )
        }else{
          Swal.fire(
            'Sorry!',
            'You dont have enough privilage',
            'error'
          )
        }
        this.getAdmins();
        this.closePass.nativeElement.click();
      })    

     
    }else{
      this.checkRe=true;
    }
  }
  edit(no:any, i:any){
    this.checkEdit=false;
    this.buttonValue="Update User";
    var apiVal = sessionStorage.getItem('api');
    this.finalData.patchValue({
      name: this.adminList[i]['adminName'],
      username: this.adminList[i]['username'],
      email: this.adminList[i]['email'],
      type: this.adminList[i]['type'],
      api: apiVal,
      id: no
    });
  }
  delete(i:any){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {        
        var apiVal = {'api': sessionStorage.getItem('api'), 'id':i};
        this.network.postdata(this.deleteAdminUrl, apiVal).subscribe(data => {          
          if(data==false){
            Swal.fire(
              'Sorry!',
              'You dont have enough privilage',
              'error'
            )
          }
          else{
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
          }
          this.getAdmins();
          this.closeadmin.nativeElement.click();
        });
      }
    })
  }

}
