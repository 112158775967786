import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormArray, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { NetworkService } from './../network.service';
import { HttpClient } from '@angular/common/http';
import {Route, ActivatedRoute} from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-check-list',
  templateUrl: './check-list.component.html',
  styleUrls: ['./check-list.component.css']
})
export class CheckListComponent implements OnInit {
  @ViewChild('cancelModel') cancelModel:any;
  getallData:any= this.network.urlInitial+"getallService.php";
  addChecklistUrl:any= this.network.urlInitial+"addChecklist.php";
  getChecklistUrl:any= this.network.urlInitial+"getChecklist.php";
  deleteCheckUrl:any= this.network.urlInitial+"deleteCheck.php"
  productsNServices:any=[];
  checklist:any=[];
  param:any=0;
  checkData:FormGroup= new FormGroup({
    productId: new FormControl(), 
    custom: new FormControl(),
    quantity: new FormControl(),
    price: new FormControl(),
    id: new FormControl(),
  });
  constructor(private route: ActivatedRoute, public fb: FormBuilder, private network: NetworkService, private http: HttpClient) { }
  getcheckList(){
    var apiVal = {'id': this.param};   
    this.network.postdata(this.getChecklistUrl , apiVal).subscribe(data => {
      this.checklist=data;
    })

  }
  addChecklist(){
    this.network.postdata(this.addChecklistUrl,this.checkData.value).subscribe(data => {
      this.getcheckList();
      this.cancelModel.nativeElement.click();
    })
  }
  delete(id:any){
    var apiVal = {'id': id};   
    this.network.postdata(this.deleteCheckUrl , apiVal).subscribe(data => {
      this.getcheckList();
    })
  }
  ngOnInit(): void {
    this.getProductNService();    
    this.route.params.subscribe((params) => {
        this.param=params['id'];
        this.getcheckList();
    });
  }
  keyword = 'name';
  getProductNService(){
    var apiVal = {'api': sessionStorage.getItem('api')};   
    this.network.postdata(this.getallData,apiVal).subscribe(data => {
      this.productsNServices = data;
    }) 
  }
  selectEvent(item:any) {
    this.checkData.patchValue({
      productId:item.name,
      id:this.param
    });
  }
  onChangeSearch(search: string) {
  }
  onFocused(e:any) {
  }
}
