<div class="app" id="app">
  <div id="aside" class="app-aside fade nav-dropdown black">
    <app-footer class="navside dk" data-layout="column"></app-footer>
  </div>
  <div id="content" class="app-content box-shadow-z2 bg pjax-container" role="main">
    <div class="app-header white bg b-b">
      <div class="navbar" data-pjax>
        <a data-toggle="modal" data-target="#aside" class="navbar-item pull-left hidden-lg-up p-r m-a-0">
          <i class="ion-navicon"></i>
        </a>
        <div class="navbar-item pull-left h5" id="pageTitle">Create Invoice</div>
      </div>
    </div>
    <div class="app-footer white bg p-a b-t">
      <div class="pull-right text-sm text-muted">Version 2.0.1</div>
      <span class="text-sm text-muted">&copy; Copyright.</span>
    </div>
    <div class="app-body">
      <div style="margin:20px; ">
        <div class="row">
          <div class="col col-6 col-md-6">
            <br>
            <div class="">
              <div class="btn-group">
                <button type="button" (click)="addTable(0)" class="btn btn-info">
                  New Custom Section
                </button>
              </div>
              <div class="btn-group m-l">
                <button type="button" (click)="addTable(1)" class="btn btn-info">
                  New Invoice Section
                </button>
              </div>
              <button class="btn btn-fw success m-l" (click)="calculate()">{{buttonText()}}</button>
              <button style="display:none;" #callPleaseWait data-toggle="modal" data-target="#m-md-wait">Please Wait</button>
              <div [formGroup]="invoceData">
                <div formArrayName="tables">
                  <form *ngFor="let tables of getTables(invoceData); let i = index">
                    <div>
                      <div class="m-t" *ngIf="tables.value.type==0">
                        <div [formGroupName]="i">
                          <h5>Table No {{i+1}}</h5>
                          <div class="form-group row">
                            <label class="col-sm-2 form-control-label">Title {{i+1}}</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" formControlName="tableTitle">
                            </div>
                            <div class="col-sm-2">
                              <a (click)="removeTables(i)" class="btn btn-icon danger" data-toggle="tooltip"
                                data-placement="top" title="Delete Table">
                                <i class="fa fa-trash"></i>
                              </a>
                            </div>
                          </div>
                          <div>
                            <div class="form-group row" *ngFor="let item of getSubTables(tables); let j=index">
                              <label class="col-sm-2 form-control-label">Column {{j+1}}</label>
                              <div class="col-sm-10" formArrayName="subTable">
                                <div class="row" [formGroupName]="j">
                                  <div class="col-md-2">
                                    <input type="text" formControlName="key" class="form-control" placeholder="Title">
                                  </div>
                                  <div class="col-md-7">
                                    <textarea type="text" formControlName="value" class="form-control"
                                      placeholder="Discription"></textarea>
                                  </div>
                                  <div class="col-md-3">
                                    <a (click)="addSubtable(i,0)" class="btn btn-icon success" data-toggle="tooltip"
                                      data-placement="top" title="Save Column">
                                      <i class="fa fa-plus"></i>
                                    </a>
                                    <a (click)="removeSubTables(i,j)" class="btn btn-icon danger" data-toggle="tooltip"
                                      data-placement="top" title="Delete Column">
                                      <i class="fa fa-trash"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="m-t" *ngIf="tables.value.type==1">
                        <div [formGroupName]="i">
                          <h5>Table No {{i+1}}</h5>
                          <div class="form-group row">
                            <label class="col-sm-2 form-control-label">Title</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" formControlName="tableTitle">
                            </div>
                            <div class="col-sm-2">
                              <a (click)="removeTables(i)" class="btn btn-icon danger" data-toggle="tooltip"
                                data-placement="top" title="Delete Table">
                                <i class="fa fa-trash"></i>
                              </a>
                            </div>
                          </div>
                          <div class="m-l" *ngFor="let item of getSubTables(tables); let k=index">
                            <p>Row {{k+1}}</p>
                            <div formArrayName="subTable">
                              <div [formGroupName]="k">
                                <div class="ng-autocomplete">
                                  <ng-autocomplete [data]="productList" [searchKeyword]="keyword"
                                    placeholder="{{item.value.name}}" (selected)='selectEvent(item,$event)'
                                    (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)'
                                    historyIdentifier="productList" [itemTemplate]="itemTemplate"
                                    [notFoundTemplate]="notFoundTemplate">
                                  </ng-autocomplete>
                                  <ng-template #itemTemplate let-item>
                                    <a [innerHTML]="item.name"></a>
                                  </ng-template>

                                  <ng-template #notFoundTemplate let-notFound>
                                    <div [innerHTML]="notFound"></div>
                                  </ng-template>
                                </div>
                                <div *ngIf="item.value.type=='1'; then thenBlock else elseBlock" class="form-group row">
                                </div>
                                <ng-template #thenBlock>
                                  <div class="form-group row m-t">
                                    <label class="col-sm-2 form-control-label">Quantity</label>
                                    <div class="col-sm-10">
                                      <input type="number" class="form-control" formControlName="quantity">
                                    </div>
                                  </div>
                                  <div class="form-group row">
                                    <label class="col-sm-2 form-control-label">Discount(%)</label>
                                    <div class="col-sm-10">
                                      <input type="number" class="form-control" formControlName="discount">
                                    </div>
                                  </div>
                                  <a (click)="addSubtable(i,1)" class="btn btn-icon success" data-toggle="tooltip"
                                    data-placement="top" title="Save Column">
                                    <i class="fa fa-plus"></i>
                                  </a>
                                  <a (click)="removeSubTables(i,k)" class="btn btn-icon danger" data-toggle="tooltip"
                                    data-placement="top" title="Delete Column">
                                    <i class="fa fa-trash"></i>
                                  </a>
                                </ng-template>
                                
                                <ng-template #elseBlock>
                                  <div class="form-group row m-t">
                                    <label class="col-sm-2 form-control-label">Price</label>
                                    <div class="col-sm-10">
                                      <input type="number" class="form-control" formControlName="v1">
                                    </div>
                                    <label class="col-sm-4 form-control-label">Profile Handle</label>
                                    <div class="col-sm-8">
                                      <input type="number" class="form-control" formControlName="v2">
                                    </div>
                                    <label class="col-sm-4 form-control-label">Labour Charge</label>
                                    <div class="col-sm-8">
                                      <input type="number" class="form-control" formControlName="v3">
                                    </div>
                                    <label class="col-sm-4 form-control-label">Transportation</label>
                                    <div class="col-sm-8">
                                      <input type="number" class="form-control" formControlName="v4">
                                    </div>
                                    <label class="col-sm-4 form-control-label">Lights</label>
                                    <div class="col-sm-8">
                                      <input type="number" class="form-control" formControlName="v5">
                                    </div>
                                    <label class="col-sm-4 form-control-label">Profit</label>
                                    <div class="col-sm-8">
                                      <input type="number" class="form-control" formControlName="v6">
                                    </div>
                                  </div>
                                  <div class="form-group row m-t">
                                    <label class="col-sm-2 form-control-label">Quantity</label>
                                    <div class="col-sm-10">
                                      <input type="number" class="form-control" formControlName="quantity">
                                    </div>
                                  </div>
                                  <div class="form-group row">
                                    <label class="col-sm-2 form-control-label">Discount(%)</label>
                                    <div class="col-sm-10">
                                      <input type="number" class="form-control" formControlName="discount">
                                    </div>
                                  </div>
                                  <a (click)="addSubtable(i,1)" class="btn btn-icon success" data-toggle="tooltip"
                                    data-placement="top" title="Save Column">
                                    <i class="fa fa-plus"></i>
                                  </a>
                                  <a (click)="removeSubTables(i,k)" class="btn btn-icon danger" data-toggle="tooltip"
                                    data-placement="top" title="Delete Column">
                                    <i class="fa fa-trash"></i>
                                  </a>
                                </ng-template>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col col-6 col-md-6" id="invoice" #pdfTable>
            <section>
              <div>
                <table class="table table-bordered b-t">
                  <thead>
                    <tr>
                      <th colspan="9">Invoice #{{invoiceNo}}</th>
                      <th colspan="9">{{dateFinal}}
                    </tr>
                    <tr>
                      <td colspan="9">
                        <div class="row">
                          <strong>Pay to:</strong>
                        </div>
                        <div class="row">
                          <img [src]="getImgContent(companyData.logo)" crossOrigin="Anonymous" width="180px">
                        </div>
                        <div class="row">
                          <h4>{{companyData.name}}</h4>
                        </div>
                        <div class="row">
                          <p>{{companyData.address}}</p>
                        </div>
                      </td>
                      <td colspan="9">
                        <div class="row">
                          <strong>Customer:</strong>
                        </div>
                        <div class="row">
                          <p>Customer Name: {{clientName}}</p>
                        </div>
                        <div class="row">
                          <p>Customer Address: {{clientaddress}}</p>
                        </div>
                        <div class="row">
                          <p>GST No:{{clientGst}}</p>
                        </div>
                      </td>
                    </tr>
                  </thead>
                </table>
              </div>
              <section>
                <div *ngFor="let tables of getTables(invoceData); let i = index">
                  <table *ngIf="tables.value.type==0">
                    <thead class="table table-bordered b-t">
                      <tr>
                        <th colspan="18">{{tables.value.tableTitle}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="table table-bordered b-t">
                        <th *ngFor="let item of getSubTables(tables); let j=index">{{item.value.key}}</th>
                      </tr>
                      <tr class="table table-bordered b-t">
                        <td *ngFor="let item of getSubTables(tables); let j=index">{{item.value.value}}</td>
                      </tr>

                    </tbody>
                  </table>
                  <table *ngIf="tables.value.type==1">
                    <thead class="table table-bordered b-t">
                      <tr>
                        <th colspan="18">
                          {{tables.value.tableTitle}}
                        </th>
                      </tr>
                      <tr>
                        <th rowspan="2">Sl.No</th>
                        <th colspan="2">Specifications</th>
                        <th rowspan="2">Quantity</th>
                        <th rowspan="2">Rate</th>
                        <th rowspan="2">Discount Amount</th>
                        <th rowspan="2">Tax(%)</th>
                        <th rowspan="2">Tax Amount</th>
                        <th rowspan="2">Total Amount</th>
                      </tr>
                      <tr>
                        <th>Item</th>
                        <th>Discription</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of getSubTables(tables); let k=index" class="table table-bordered b-t">
                        <td>{{k+1}}</td>
                        <td>{{item.value.name}}</td>
                        <td>
                          <p *ngIf="item.value.im==0">{{item.value.disc}}</p>
                          <img *ngIf="item.value.im==1" width="80px" [src]="getImage(item.value.image)" alt="">
                        </td>
                        <td>{{item.value.quantity}}</td>
                        <td>{{item.value.price}}</td>
                        <td>{{item.value.quantity*item.value.price*item.value.discount/100}}</td>
                        <td>{{item.value.tax}}</td>
                        <td>
                          {{((((item.value.quantity*item.value.price)-(item.value.quantity*item.value.price*item.value.discount/100))*item.value.tax/100))|number:'1.0-0'}}
                        </td>
                        <td>
                          {{((((item.value.quantity*item.value.price)-(item.value.quantity*item.value.price*item.value.discount/100))+((item.value.quantity*item.value.price)-(item.value.quantity*item.value.price*item.value.discount/100))*item.value.tax/100))|number:'1.0-0'}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
            </section>
            <div>
              <table class="table table-bordered b-t">
                <tr>
                  <th colspan="6">Subtotal</th>
                  <td>₹ {{generateInvoice(0)|number:'1.0-0'}}</td>
                </tr>
                <tr>
                  <th colspan="6">CGST</th>
                  <td>₹ {{generateInvoice(1)|number:'1.0-0'}}</td>
                </tr>
                <tr>
                  <th colspan="6">SGST</th>
                  <td>₹ {{generateInvoice(1)|number:'1.0-0'}}</td>
                </tr>
                <tr>
                  <th colspan="6">Total</th>
                  <td>₹ {{generateInvoice(2)|number:'1.0-0'}}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="m-md" class="modal" data-backdrop="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Client</h5>
      </div>
      <div class="modal-body text-center p-lg">
        <label class="m-b">
          <input type="checkbox" (click)="changeClient()">
          <i class="indigo"></i>
          Old Client ?
        </label>
        <br>
        <form *ngIf="!oldClient" [formGroup]="clientData" (ngSubmit)="addClient()">
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">Client's Name:</label>
            <div class="col-sm-10">
              <input type="text" class="form-control" formControlName="name">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">Client's Email:</label>
            <div class="col-sm-10">
              <input type="text" class="form-control" formControlName="email">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">Client's Address:</label>
            <div class="col-sm-10">
              <textarea type="text" class="form-control" formControlName="address"></textarea>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">Client's GST No:</label>
            <div class="col-sm-10">
              <input type="text" class="form-control" formControlName="gst">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">Client's Phone No:</label>
            <div class="col-sm-10">
              <input type="text" class="form-control" formControlName="phoneNo">
            </div>
          </div>
          <button type="submit" class="btn btn-info m-t">Save Client</button>
        </form>
        <form *ngIf="oldClient">
          <div class="row">
            <div class="p-x-md m-y">
              <ng-autocomplete [data]="clientList" [searchKeyword]="keyword"
                placeholder="Enter the Product/Service Name" (selected)='selectClient($event)'
                (inputChanged)='onChangeSearchClient($event)' (inputFocused)='onFocusedClient($event)'
                historyIdentifier="clientList" [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
              </ng-autocomplete>

              <ng-template #itemTemplate let-item>
                <a [innerHTML]="item.name"></a>
              </ng-template>

              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>
          </div>
          <a class="btn btn-info m-t" (click)="saveNext()">Next</a>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn danger p-x-md" #closeclient data-dismiss="modal">Close</button>
      </div>
    </div><!-- /.modal-content -->
  </div>
</div>
<div id="m-md-date" class="modal" data-backdrop="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Client</h5>
      </div>
      <div class="modal-body text-center p-lg">
        <label class="m-b">
          <input type="checkbox" (click)="checkDateInvo(0)">
          <i class="indigo"></i>
          Use Todays Date ?
        </label>
        &nbsp;&nbsp;
        <!-- <label class="m-b">
          <input type="checkbox" (click)="checkDateInvo(1)">
          <i class="indigo"></i>
          Use Dynamic Invoice Number?
        </label> -->
        <br>
        <form [formGroup]="dateData" (ngSubmit)="addDateInvo()">
          <div class="form-group row" *ngIf="!dateCheck">
            <label class="col-sm-2 form-control-label">Invoice Date:</label>
            <div class="col-sm-10">
              <input type='date' value="01/12/1999" formControlName="date" class="form-control" />
            </div>
          </div>
          <div class="form-group row" *ngIf="!invoNoCheck">
            <label class="col-sm-2 form-control-label">Invoice Number:</label>
            <div class="col-sm-10">
              <input type="text" class="form-control" formControlName="invoice">
            </div>
          </div>
          <button type="submit" class="btn btn-info m-t">Save Data</button>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn danger p-x-md" #closeDate data-dismiss="modal">Close</button>
      </div>
    </div><!-- /.modal-content -->
  </div>
</div>
<!-- large modal -->
<div id="m-md-wait" class="modal" data-backdrop="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-body text-center p-lg">
        <p>Please Wait</p>
        <img src="http://intown.biz/demo/wait/waiting.gif" />
      </div>
    </div><!-- /.modal-content -->
  </div>
</div>
<!-- / .modal -->
