<div class="app" id="app">
  <div class="padding">
    <div class="navbar">
      <div class="pull-center">
        <a href="index.html" class="navbar-brand">
          <div data-ui-include="./assets/images/logo.svg"></div>
          <img src="./assets/images/logo_black.png" alt=".">
          <span class="hidden-folded inline" class="hide">Etlite</span>
        </a>
      </div>
    </div>
  </div>
  <div class="b-t">
    <div class="center-block w-xxl w-auto-xs p-y-md text-center">
      <div class="p-a-md">
        <form name="form" [formGroup]="loginData" (ngSubmit)="login()">
          <div class="form-group">
            <input type="email" class="form-control" placeholder="Email" required formControlName="username">
          </div>
          <div class="form-group">
            <input type="password" class="form-control" placeholder="password" required autocomplete="on" formControlName="password">
          </div>
          <div class="m-b-md">
            <label class="md-check">
              <input type="checkbox" formControlName="session"><i class="primary"></i> Keep me signed in
            </label>
          </div>
          <div class="m-b-md">
            <p class={{messageColour}} *ngIf="errorMessage">{{message}}</p>
          </div>
          <button type="submit" class="btn btn-lg black p-x-lg">Sign in</button>
        </form>
        <div class="m-y">
          <a href="/forgot" class="_600">Forgot password?</a>
        </div>
      </div>
    </div>
  </div>
</div>
