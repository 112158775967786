import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {ConnectionServiceModule} from 'ng-connection-service';  
import { DataTablesModule } from "angular-datatables";
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { ForgotComponent } from './forgot/forgot.component';
import { FooterComponent } from './footer/footer.component';
import { CreateInvoiceComponent } from './create-invoice/create-invoice.component';
import { ClientListComponent } from './client-list/client-list.component';
import { ProductsComponent } from './products/products.component';
import { ServicesComponent } from './services/services.component';
import { AdminsComponent } from './admins/admins.component';
import { SettingsComponent } from './settings/settings.component';
import { HelpComponent } from './help/help.component';
import { CheckListComponent } from './check-list/check-list.component';
import { NoInternetComponent } from './no-internet/no-internet.component';
import { DisplayComponent } from './display/display.component';
import { EditInvoiceComponent } from './edit-invoice/edit-invoice.component';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotComponent,
    FooterComponent,
    CreateInvoiceComponent,
    ClientListComponent,
    ProductsComponent,
    ServicesComponent,
    AdminsComponent,
    SettingsComponent,
    HelpComponent,
    CheckListComponent,
    NoInternetComponent,
    DisplayComponent,
    EditInvoiceComponent,
    InvoiceListComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AutocompleteLibModule,
    ReactiveFormsModule,
    HttpClientModule,
    ConnectionServiceModule,
    DataTablesModule,
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
