<div class="app" id="app">
  <div id="aside" class="app-aside fade nav-dropdown black">
    <app-footer class="navside dk" data-layout="column"></app-footer>
  </div>
  <!-- content -->
  <div id="content" class="app-content box-shadow-z2 bg pjax-container" role="main">
    <div class="app-header white bg b-b">
      <div class="navbar" data-pjax>
        <a data-toggle="modal" data-target="#aside" class="navbar-item pull-left hidden-lg-up p-r m-a-0">
          <i class="ion-navicon"></i>
        </a>
        <div class="navbar-item pull-left h5" id="pageTitle">Settings</div>
      </div>
    </div>
    <div class="app-body">
      <form role="form" class="p-a-md" [formGroup]="finalData" (ngSubmit)="saveSettings()">
        <div class="p-a-md col-md-6">
          <div class="form-group">
            <label>Logo:</label>
            <div class="form-file">
              <input type="file" accept="image/*" (change)="processFile($event)">
              <button class="btn white">Upload logo</button>
            </div>
          </div>
          <div class="form-group">
            <label>Company Name:</label>
            <input type="text" class="form-control" formControlName="name">
          </div>
          <div class="form-group">
            <label>Company Address:</label>
            <textarea class="form-control" cols="5" formControlName="address"></textarea>
          </div>
          <div class="form-group">
            <label>Company GST:</label>
            <input type="text" class="form-control" formControlName="gst">
          </div>
          <div class="form-group">
            <label>Company PAN:</label>
            <input type="text" class="form-control" formControlName="pan">
          </div>
          <div class="form-group">
            <label>Company Account Name:</label>
            <input type="text" class="form-control" formControlName="accName">
          </div>
        </div>
        <div class="p-a-md col-md-6">
          <div class="form-group">
            <img src="{{logo}}" width="80" alt="">
          </div>
          <div class="form-group">
            <label>Company Account Number:</label>
            <input type="text" class="form-control" formControlName="accNo">
          </div>
          <div class="form-group">
            <label>Company IFSC Code:</label>
            <input type="text" class="form-control" formControlName="ifsc">
          </div>
          <div class="form-group">
            <label>Company Swift Code:</label>
            <input type="text" class="form-control" formControlName="swift">
          </div>
          <div class="form-group">
            <label>Company Bank Name:</label>
            <input type="text" class="form-control" formControlName="bank">
          </div>
          <div class="form-group">
            <label>Company Branch Name:</label>
            <input type="text" class="form-control" formControlName="branch">
          </div>
        </div>
        <div class="col-md-12">
          <label>Terms and Conditions:</label>
          <div>
            <textarea rows="4" class="form-control" formControlName="tnc"></textarea>
          </div>
          <button type="submit" class="btn btn-info m-t">Update</button>
        </div>
      </form>
    </div>
  </div>
</div>
