<div id="content" class="app-content" role="main">
  <div class="app-body">

    <!-- ############ PAGE START-->

    <div class="row-col indigo h-v">
      <div class="row-cell v-m">
        <div class="text-center col-sm-6 offset-sm-3 p-y-lg">
            <p class="m-y text-muted h1">
                <i class="fa fa-warning"></i>
            </p>    
          <h3 class="display-3 m-y-lg">Sorry Internet Connection Lost.</h3>
        </div>
      </div>
    </div>

    <!-- ############ PAGE END-->

  </div>
</div>
