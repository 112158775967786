import { Component, OnInit } from '@angular/core';
import { LoginServiceService } from './login-service.service';
import { ConnectionService } from 'ng-connection-service';  

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  isConnected = true;  
  noInternetConnection: boolean =false;  

  ngOnInit():void{
    this.login.sessionControl();
  }
  constructor(public login:LoginServiceService, private connectionService: ConnectionService){
    this.connectionService.monitor().subscribe(isConnected => {  
      this.isConnected = isConnected;  
      if (this.isConnected) {  
        this.noInternetConnection=false;  
      }  
      else {  
        this.noInternetConnection=true;  
      }  
    })  
  }
}
