<div class="navbar no-radius">
  <a href="index.html" class="navbar-brand">
    <div data-ui-include="'./assets/images/logo.svg'"></div>
    <img src="./assets/images/logo_white.png" alt=".">
    <span class="hidden-folded inline" class="hide">aside</span>
  </a>
</div>
<div data-flex class="hide-scroll">
  <nav class="scroll nav-stacked nav-stacked-rounded nav-color">
    <ul class="nav" data-ui-nav>
      <div class="dropdown-divider"></div>
      <li>
        <a routerLink="/create" class="b-danger">
          <span class="nav-icon text-white no-fade">
            <i class="ion-filing"></i>
          </span>
          <span class="nav-text">Create Invoice</span>
        </a>
      </li>
      <div class="dropdown-divider"></div>
      <li>
        <a routerLink="/client" class="b-success">
          <span class="nav-icon text-white no-fade">
            <i class="ion-person"></i>
          </span>
          <span class="nav-text">Client List</span>
        </a>
      </li>
      <div class="dropdown-divider"></div>
      <li>
        <a routerLink="/invoice" class="b-danger">
          <span class="nav-icon text-white no-fade">
            <i class="ion-person"></i>
          </span>
          <span class="nav-text">Invoice List</span>
        </a>
      </li>
      <div class="dropdown-divider"></div>
      <li>
        <a routerLink="/products" class="b-success">
          <span class="nav-icon text-white no-fade">
            <i class="ion-cube"></i>
          </span>
          <span class="nav-text">Products</span>
        </a>
      </li>
      <div class="dropdown-divider"></div>
      <li>
        <a routerLink="/services" class="b-danger">
          <span class="nav-icon text-white no-fade">
            <i class="ion-wrench"></i>
          </span>
          <span class="nav-text">Services</span>
        </a>
      </li>
      <div class="dropdown-divider"></div>
      <li>
        <a routerLink="/admins" class="b-success">
          <span class="nav-icon text-white no-fade">
            <i class="ion-person-add"></i>
          </span>
          <span class="nav-text">Admins</span>
        </a>
      </li>
      <div class="dropdown-divider"></div>
      <li>
        <a routerLink="/settings" class="b-danger">
          <span class="nav-icon text-white no-fade">
            <i class="ion-gear-b"></i>
          </span>
          <span class="nav-text">Settings</span>
        </a>
      </li>
      <div class="dropdown-divider"></div>
      <li>
        <a target="_blank" href="https://docs.google.com/spreadsheets/d/1s9p9sn1VjYiHLKp2D_x1xBC-IXdz55Uw/edit?usp=sharing&ouid=114535769830206618329&rtpof=true&sd=true" class="b-info">
          <span class="nav-icon text-white no-fade">
            <i class="fa fa-anchor"></i>
          </span>
          <span class="nav-text">Old Clients</span>
        </a>
      </li>
      <div class="dropdown-divider"></div>
    </ul>
  </nav>
</div>
<div class="dropdown-divider"></div>
<div data-flex-no-shrink>
  <div class="nav-fold dropup">
    <a data-toggle="dropdown">
      <div class="pull-left">
        <div class="inline"><span class="avatar w-40 grey">{{smallUname}}</span></div>
        <img src="./assets/images/a0.jpg" alt="..." class="w-40 img-circle hide">
      </div>
      <div class="clear hidden-folded p-x">
        <h6 class="">{{name}}</h6>
      </div>
    </a>
    <div class="dropdown-menu w dropdown-menu-scale ">
      <a class="dropdown-item" routerLink="/help">
        Need help?
      </a>
      <div class="dropdown-divider"></div>
      <a class="dropdown-item" (click)="logout()">Sign out</a>
    </div>
  </div>
</div>
