import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators, FormBuilder } from '@angular/forms';
import { NetworkService } from './../network.service';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  logo: string='./../../assets/images/a0.jpg';
  uploadUrl:string=this.network.urlInitial+"settingsUpload.php";
  getUrl:string=this.network.urlInitial+"settingsGet.php";
  tnc="<p>Terms and Conditions</p>";
  constructor(public fb: FormBuilder, private network: NetworkService) { }
  ngOnInit(): void {
    this.getSetting();
  }

  finalData = this.fb.group({
    logo: new FormControl('', [Validators.required]), name: '', address: '', gst: '', pan: '', accName: '', accNo: '', ifsc: '', swift: '', bank: '', branch: '', tnc: '', api: ''
  });

  processFile(imageInput:any){
    const reader = new FileReader();
    if(imageInput.target.files && imageInput.target.files.length) {
      const [file] = imageInput.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.logo = reader.result as string;
        this.finalData.patchValue({
          logo: reader.result
        });
      };
    }
  }

  getSetting():void{
    var apiVal = {'api': sessionStorage.getItem('api')};    
    this.network.postdata(this.getUrl,apiVal).subscribe(data => {
      this.logo=this.network.urlInitial+data['logo'];      
      this.tnc=data['tnC'];
      this.finalData.patchValue({
        name: data['name'],
        address: data['address'],
        gst: data['gst'],
        pan: data['pan'],
        accName: data['accName'],
        accNo: data['accNo'],
        ifsc: data['ifsc'],
        swift: data['swift'],
        bank: data['bank'],
        branch: data['branch'],
        tnc: data['tnC']
      });
    });

  }

  saveSettings():void{
    var apiVal = sessionStorage.getItem('api');    
    this.finalData.patchValue({
      api: apiVal
    });
    console.log(this.finalData.value);
    
    this.network.postdata(this.uploadUrl,this.finalData.value).subscribe(data => {
      if(data==true){
        Swal.fire("Awesome!", "Sucessfully Updated!", "success");
      }
    });
  }
}
