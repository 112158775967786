import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators, FormBuilder } from '@angular/forms';
import { NetworkService } from './../network.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css']
})
export class ForgotComponent implements OnInit {

  constructor(public fb: FormBuilder, private network: NetworkService, private router: Router) { }

  loginData = this.fb.group({
    username: ''
  });

  forgotPasswordUrl:string=this.network.urlInitial+"forgotPassword.php";

  forgotPassword(){
    this.network.postdata(this.forgotPasswordUrl,this.loginData.value).subscribe(data => {
      console.log(data);
    })
  }

  ngOnInit(): void {
  }

}
