<div class="app" id="app">
  <div id="aside" class="app-aside fade nav-dropdown black">
    <app-footer class="navside dk" data-layout="column"></app-footer>
  </div>
  <!-- / -->
  <!-- content -->
  <div id="content" class="app-content box-shadow-z2 bg pjax-container" role="main">
    <div class="app-header white bg b-b">
      <div class="navbar" data-pjax>
        <a data-toggle="modal" data-target="#aside" class="navbar-item pull-left hidden-lg-up p-r m-a-0">
          <i class="ion-navicon"></i>
        </a>
        <div class="navbar-item pull-left h5" id="pageTitle">Services</div>
      </div>
    </div>
    <div class="app-footer white bg p-a b-t">
      <div class="pull-right text-sm text-muted">Version 1.0.1</div>
      <span class="text-sm text-muted">&copy; Copyright.</span>
    </div>
    <div class="app-body">
      <!-- only need a height for layout 4 -->
      <div class="box container">
        <div class="row">
          <button (click)="selectSave()" class="btn btn-fw info" style="margin: 20px;" data-toggle="modal" data-target="#m-md"><i
              class="fa fa-plus" style="padding: 2px 6px;"></i>Add Service</button>
        </div>
        <div class="row">
          <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered"
            style="width:100%">
            <thead>
              <tr>
                <th>Sl.No</th>
                <th>Service Name</th>
                <th>Service Discription</th>
                <th>Service Image</th>
                <th>HSN Code</th>
                <th>Tax(%)</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of serviceList; let i=index;">
                <td>{{i+1}}</td>
                <td>{{item.name}}</td>
                <td>{{item.disc}}</td>
                <td><img src="{{imageUrl}}{{item.image}}" width="80px" alt=""></td>
                <td>{{item.hsncode}}</td>
                <td>{{item.tax}}</td>
                <td>
                  <button (click)="edit(item.slNo, i)" class="btn success" style="margin-left: 5px;" data-toggle="modal"
                    data-target="#m-md"><i class="fa fa-edit" data-toggle="tooltip" data-placement="top"
                      title="Edit Data"></i></button>
                  <button (click)="delete(item.slNo)" class="btn danger" style="margin-left: 5px;"><i
                      class="fa fa-trash" data-toggle="tooltip" data-placement="top" title="Delete"></i></button>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>Sl.No</th>
                <th>Service Name</th>
                <th>Service Discription</th>
                <th>Service Image</th>
                <th>HSN Code</th>
                <th>Tax(%)</th>
                <th>Actions</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- large modal -->
<div id="m-md" class="modal" data-backdrop="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Add service</h5>
      </div>
      <div class="modal-body text-center p-lg" *ngIf="clicked; else elseBlock">
        <p>Please Wait</p>
        <img src="http://intown.biz/demo/wait/waiting.gif" />
        <div class="modal-footer">
          <button type="button" class="btn danger p-x-md" #closeupload data-dismiss="modal">Close</button>
        </div>
      </div>
      <ng-template #elseBlock>
        <form role="form" class="p-a-md" [formGroup]="finalData" (ngSubmit)="saveservice()">
          <div class="modal-body text-center p-lg">
            <div class="form-group row">
              <label class="col-sm-2 form-control-label">Name:</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" formControlName="name">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-2 form-control-label">Discription</label>
              <div class="col-sm-10">
                <textarea class="form-control" rows="5" formControlName="disc"></textarea>
              </div>
            </div>
            <div class="form-group row">
              <img *ngIf="checkEdit" width="60px" src="{{image}}" alt="">
              <label class="col-sm-2 form-control-label">Image input</label>
              <div class="col-sm-2">
                <div class="form-file">
                  <input type="file" accept="image/*" (change)="processFile($event)">
                  <button class="btn white">{{imagename}}</button>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-2 form-control-label">HSN Code:</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" formControlName="hsncode">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-2 form-control-label">Tax Percentage:</label>
              <div class="col-sm-10">
                <input type="number" class="form-control" formControlName="tax">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn danger p-x-md" #closeupload data-dismiss="modal">Cancel</button>
            <button type="submit" class="btn success p-x-md">{{buttonValue}}</button>
          </div>
        </form>
      </ng-template>
    </div><!-- /.modal-content -->
  </div>
</div>
<!-- / .modal -->
