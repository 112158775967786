import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormArray, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { NetworkService } from './../network.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import Swal from 'sweetalert2';

import jspdf from 'jspdf';  
import html2canvas from 'html2canvas'; 

declare var $: any;
@Component({
  selector: 'app-create-invoice',
  templateUrl: './create-invoice.component.html',
  styleUrls: ['./create-invoice.component.css']
})
export class CreateInvoiceComponent implements OnInit {
  @ViewChild('closeclient') closeclient:any;
  @ViewChild('closeDate') closeDate:any;
  @ViewChild('callPleaseWait') callPleaseWait:any;
  @ViewChild('client') clientAl:any;
  @ViewChild('invoice') invoice:any;

  ngOnInit(): void {
    this.getProductNService();    
    this.getCompanyProfile();
    this.getClientList();
    this.getLatest();
  }
  constructor(public fb: FormBuilder, private network: NetworkService, private http: HttpClient, private sanitizer:DomSanitizer) { 
    this.invoceData.valueChanges.subscribe(x => {
      this.calculateString="Calculate";
      this.calculateCheck=false;    
    })  
  }
  keyword:any = 'name';
  basePath:any=this.network.urlInitial;
  isLoadingResult:boolean=true;
  getallData:any= this.network.urlInitial+"getallService.php";
  getuserDataUrl:string=this.network.urlInitial+"settingsGet.php";
  getClientListUrl:string=this.network.urlInitial+"clientGet.php";
  addClientListUrl:string=this.network.urlInitial+"addClient.php";
  addInvoiceUrl:string=this.network.urlInitial+"addInvoice.php";
  getLatestUrl:string=this.network.urlInitial+"getLatest.php";
  productList:any=[];
  clientList:any=[];
  companyData:any={logo:''};
  currentTableType:any=0;
  i:any;
  calculateString:any="Calculate";
  calculateCheck:boolean=false;
  subTotal:any=0;
  ttaxAmt:any=0;
  sTotal:any=0;
  tdiscountAmt:any=0;
  oldClient:boolean=false;
  clientDatax:any={}
  clientName:any="";
  clientEmail:any="";
  clientGst:any="";
  clientPhoneno:any="";
  clientaddress:any="";
  clientData:FormGroup= new FormGroup({
    address: new FormControl(),
    email: new FormControl(),
    gst: new FormControl(),
    name: new FormControl(),
    phoneNo: new FormControl(),
    slNo: new FormControl(),
    api: new FormControl(),
  });
  dateData:FormGroup=new FormGroup({
    date: new FormControl(),
    invoice: new FormControl(),
  })
  invoiceNo:any=0;
  dateFinal:any="1999-12-01"
  invoNoCheck:boolean=false;
  dateCheck:boolean=false;
  invoceData: FormGroup= new FormGroup({
    tables: new FormArray([]),
    clientId: new FormControl(''),
    date: new FormControl(''),
    invoiceNo: new FormControl('')
  });
  clientId:any="";
  finalValues:any={
    "tables":[
      {
          "tableTitle": "dnbrjf",
          "type": 0,
          "subTable": [
              {
                  "key": "njne",
                  "value": "njjv"
              },
              {
                  "key": "d bh",
                  "value": "fjh"
              },
              {
                  "key": "avbdh",
                  "value": "hbvhds"
              }
          ]
      },
      {
          "tableTitle": "asj",
          "type": 1,
          "subTable": [
              {
                  "discount": "10",
                  "quantity": "10",
                  "disc": "fsfjvjf sjvvfnjqq iosjvio",
                  "hsncode": "1000AB",
                  "image": "upload/60e457de51a8e.jpeg",
                  "name": "Product 1",
                  "price": "100",
                  "tax": "10",
                  "type": "1",
                  "discAmt": "",
                  "taxAmt": "",
                  "totalAmt": ""
              }
          ]
      }
  ]
  }
  getLatest(){
    this.network.getdata(this.getLatestUrl).subscribe(data => {
      this.finalValues=data.tables;
      this.recreate()
    }) 
  }
  recreate(){
    let i=-1,j=0;
    let tables= this.finalValues;    
    tables.forEach((item:any, index:any)=>{
      this.addTable(item.type);  
      i+=1;j=0;
      item.subTable.forEach((itema:any, indexa:any)=>{                
        this.addSubtable(index, item.type)
        j+=1;
      });
      this.removeSubTables(i,j);
    });
    this.invoceData.controls.tables.patchValue(tables);
  }

  getProductNService(){
    var apiVal = {'api': sessionStorage.getItem('api')};   
    this.network.postdata(this.getallData,apiVal).subscribe(data => {
      this.productList = data;
    }) 
  }
  getCompanyProfile(){
    var apiVal = {'api': sessionStorage.getItem('api')};   
    this.network.postdata(this.getuserDataUrl,apiVal).subscribe(data => {
      this.companyData=data;
    })
  }
  getClientList(){
    var apiVal = {'api': sessionStorage.getItem('api')};   
    this.network.postdata(this.getClientListUrl,apiVal).subscribe(data => {
      this.clientList=data;
    })
  }
  selectEvent(itema:any,item:any) {    
    const valueat=<FormControl>itema.get('productId');
    valueat.setValue(item.productId);
    const valueat1=<FormControl>itema.get('disc');
    valueat1.setValue(item.disc);
    const valueat2=<FormControl>itema.get('hsncode');
    valueat2.setValue(item.hsncode);
    const valueat3=<FormControl>itema.get('image');
    valueat3.setValue(item.image);
    const valueat4=<FormControl>itema.get('name');
    valueat4.setValue(item.name);
    const valueat5=<FormControl>itema.get('price');
    valueat5.setValue(item.price);
    const valueat6=<FormControl>itema.get('tax');
    valueat6.setValue(item.tax);
    const valueat7=<FormControl>itema.get('type');
    valueat7.setValue(item.type);
    const valueat8=<FormControl>itema.get('im');
    valueat8.setValue(item.im);    
  }
  onChangeSearch(search: string) {
    this.isLoadingResult = true;
  }
  onFocused(e:any) {}
  addTable(index:any){
    this.calculateCheck=false;
    const control = <FormArray>this.invoceData.get('tables');
    control.push(this.initTable(index));
  }
  addSubtable(index:any, type:any){
    this.calculateCheck=false;
    const control = <FormArray>this.invoceData.get('tables');
    const con=<FormArray>control.controls[index].get('subTable');
    con.push(this.subTable(index,type));
  }
  initTable(i:any){
    this.currentTableType=i;
    return new FormGroup({
      tableTitle: new FormControl(''),
      type:new FormControl(i),
      subTable: new FormArray([this.subTable(0,i)])
    });
  }
  subTable(i:any,x:any){
    this.calculateCheck=false;
    if(x==0){
      return new FormGroup({
        key: new FormControl(''),
        value: new FormControl('')
      })
    }else{
      return new FormGroup({
        productId: new FormControl(''),
        discount: new FormControl(''),
        quantity: new FormControl(''),
        disc: new FormControl(''),
        hsncode: new FormControl(''),
        image: new FormControl(''),
        name: new FormControl(''),
        price: new FormControl(''),
        tax: new FormControl(''),
        type: new FormControl(''),
        discAmt: new FormControl(''),
        taxAmt: new FormControl(''),
        totalAmt: new FormControl(''),
        im: new FormControl(''),
        v1: new FormControl(0),
        v2: new FormControl(0),
        v3: new FormControl(0),
        v4: new FormControl(0),
        v5: new FormControl(0),
        v6: new FormControl(0)
      })
    }
  }
  getTables(form:any) {    
    return form.controls.tables.controls;
  }
  getSubTables(form:any){    
    return form.controls.subTable.controls;
  }
  removeTables(j:any){
    this.calculateCheck=false;
    const control = <FormArray>this.invoceData.get('tables');
    control.removeAt(j);
  }
  removeSubTables(i:any,j:any){
    this.calculateCheck=false;
    const control = <FormArray>this.invoceData.get('tables');
    const con=<FormArray>control.controls[i].get('subTable');
    con.removeAt(j);
  }
  generateInvoice(i:any){
    if(i==0){
      return this.subTotal;
    }
    else if(i==1){
      return this.ttaxAmt/2;
    }
    else if(i==2){
      return this.sTotal
    }
  }
  calculate(){
    if(this.calculateCheck){
      if(this.clientId==""){
        Swal.fire({
          title: 'Sorry please add a client',
          showCancelButton: true,
          confirmButtonText: `Add Client`,
        }).then((result) => {
          if (result.isConfirmed) {
            this.clientAl.nativeElement.click();
          }
        })
      }else if(this.invoiceNo==0){
        Swal.fire({
          title: 'Sorry please add invoice details',
          showCancelButton: true,
          confirmButtonText: `Add Date/Invoice`,
        }).then((result) => {
          if (result.isConfirmed) {
            this.invoice.nativeElement.click();
          }
        })
      }
      else{
        this.callPleaseWait.nativeElement.click();
        this.network.postdata(this.addInvoiceUrl,this.invoceData.value).subscribe(data => {
          this.openDisplay(data)
        })
      }
    }else{
      this.invoceData.patchValue({
        clientId: this.clientId,
        date: this.dateFinal,
        invoiceNo: this.invoiceNo
      });  
      console.log(this.invoceData.value);
      
      this.tdiscountAmt = 0;
      this.ttaxAmt = 0;
      this.sTotal = 0;
      this.subTotal = 0;
      const control = <FormArray>this.invoceData.get('tables');
      control.value.forEach((item:any, index:any)=>{
        if(item.type==1){
          item.subTable.forEach((subitem:any)=>{
            if(subitem.type==0){
              subitem.price=subitem.v1+subitem.v2+subitem.v3+subitem.v4+subitem.v5+subitem.v6
            }
            this.tdiscountAmt+=(subitem.quantity*subitem.price*subitem.discount/100);
            this.ttaxAmt+=(((subitem.quantity*subitem.price)-(subitem.quantity*subitem.price*subitem.discount/100))*subitem.tax/100);
            this.sTotal+=(((subitem.quantity*subitem.price)-(subitem.quantity*subitem.price*subitem.discount/100))+((subitem.quantity*subitem.price)-(subitem.quantity*subitem.price*subitem.discount/100))*subitem.tax/100);
            this.subTotal+=((subitem.quantity*subitem.price)-(subitem.quantity*subitem.price*subitem.discount/100));
            this.calculateCheck=true;
          })        
        }
      })
    }
  }
  buttonText(){
    if(this.calculateCheck){
      return "Generate Invoice";
    }else{
      return "Calculate"
    }
  }
  selectClient(item:any){
    this.clientDatax=item;    
    this.clientId=item.slNo;    
  }
  onChangeSearchClient(search: string) {
    this.isLoadingResult = true;
  }
  onFocusedClient(e:any) {
  }
  changeClient(){
    this.oldClient=!this.oldClient;
  }
  saveNext(){
    this.clientName=this.clientDatax.name;
    this.clientEmail=this.clientDatax.email;
    this.clientGst=this.clientDatax.gst;
    this.clientPhoneno=this.clientDatax.phoneNo;
    this.clientaddress=this.clientDatax.address; 
    this.closeclient.nativeElement.click();
  }
  addClient(){
    this.clientName=this.clientData.value.name;
    this.clientEmail=this.clientData.value.email;
    this.clientGst=this.clientData.value.gst;
    this.clientPhoneno=this.clientData.value.phoneNo;
    this.clientaddress=this.clientData.value.address;  
    var apiVal = sessionStorage.getItem('api');
    this.clientData.patchValue({
      api:apiVal
    });
    this.network.postdata(this.addClientListUrl,this.clientData.value).subscribe(data => {
      this.clientId=data;
      this.clientList=data;
      this.closeclient.nativeElement.click();
    })
  }
  checkDateInvo(i:any){
    if(i==0){
      this.dateCheck=!this.dateCheck;
    }
    else{
      this.invoNoCheck=!this.invoNoCheck;
    }
  }
  addDateInvo(){
    this.closeDate.nativeElement.click();
    if(this.dateCheck && this.invoNoCheck){
      var d= new Date();
      if((d.getMonth()+1)<10){
        this.dateFinal=(d.getFullYear()+'-0'+(d.getMonth()+1)+'-'+d.getDate());
      }else{
        this.dateFinal=d.getFullYear()+'-'+(d.getMonth()+1)+'-'+d.getDate();
      }
      this.invoiceNo="1000";
    }
    else if(this.dateCheck && !this.invoNoCheck){    
      var d= new Date();
      if(d.getMonth()<10){
        this.dateFinal=d.getFullYear()+'-0'+d.getMonth()+'-'+d.getDate();
      }else{
        this.dateFinal=d.getFullYear()+'-'+d.getMonth()+'-'+d.getDate();
      }
      this.invoiceNo=this.dateData.value.invoice;
    }
    else if(!this.dateCheck && this.invoNoCheck){
      this.dateFinal=this.dateData.value.date;
      this.invoiceNo="1000";
    }else{
      this.dateFinal=this.dateData.value.date;
      this.invoiceNo=this.dateData.value.invoice;
    }
  }
  getImgContent(image:any){    
    let imgFile=this.basePath+image;
    return this.sanitizer.bypassSecurityTrustUrl(imgFile);
  }
  getImage(image:any){
    return this.basePath+image;
  }
  getBase64Image(image:any) {
    let img=this.basePath+image;
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx =<any>canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    }
  transform(base64Image:any){
    return this.sanitizer.bypassSecurityTrustResourceUrl(base64Image);
  }
  generateInvoicePdf(){
    var data =<any>document.getElementById('invoice');
    html2canvas(data).then(canvas => {  
      let imgWidth = 150;   
      let pageHeight = 200;    
      let imgHeight = canvas.height * imgWidth / canvas.width;  
      let heightLeft = imgHeight;  
      const contentDataURL = canvas.toDataURL('image/png')  
      let pdf = new jspdf('p', 'mm', 'a4'); 
      let position = 0;  
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)  
      pdf.save('MYPdf.pdf');
    });  
  }

  toDataURL(url:any) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        return reader.result;
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }
  

  converttob(url:any){
    let imgFile=this.basePath+url;
    return this.toDataURL(url)
  }

  openDisplay(id:any){
    var api=sessionStorage.getItem('api');
    var url="/pdf/invoice/?key="+api+"&id="+id;
    window.open(url);
    window.location.reload();
  }
}
