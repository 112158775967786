<div class="app" id="app">
  <div id="aside" class="app-aside fade nav-dropdown black">
    <app-footer class="navside dk" data-layout="column"></app-footer>
  </div>
  <!-- / -->
  <!-- content -->
  <div id="content" class="app-content box-shadow-z2 bg pjax-container" role="main">
    <div class="app-header white bg b-b">
      <div class="navbar" data-pjax>
        <a data-toggle="modal" data-target="#aside" class="navbar-item pull-left hidden-lg-up p-r m-a-0">
          <i class="ion-navicon"></i>
        </a>
        <div class="navbar-item pull-left h5" id="pageTitle">Client List</div>
      </div>
    </div>
    <div class="app-footer white bg p-a b-t">
      <div class="pull-right text-sm text-muted">Version 1.0.1</div>
      <span class="text-sm text-muted">&copy; Copyright.</span>
    </div>
    <div class="app-body">
      <!-- only need a height for layout 4 -->
      <div class="box container">
        <div class="row">
          <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered" style="width:100%">
            <thead>
              <tr>
                <th>Sl.No</th>
                <th>Client Name</th>
                <th>Phone Number</th>
                <th>Email Id</th>
                <th>Address</th>
                <th>GST No</th>
                <th>Added By</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of clientList; let i=index;">
                <td>{{i+1}}</td>
                <td>{{item.name}}</td>
                <td>{{item.phoneNo}}</td>
                <td>{{item.email}}</td>
                <td>{{item.address}}</td>
                <td>{{item.gst}}</td>
                <td>{{item.username}}</td>
                <td><button (click)="getList(item.slNo)" data-toggle="modal" data-target="#m-md"  class="btn info" style="margin-left: 5px; margin-top: 2px;"><i class="fa fa-file-text"
                  data-toggle="tooltip" data-placement="top" title="View Doc"></i></button>
                  <button style="margin-left: 5px; margin-top: 2px;" data-toggle="modal" data-target="#m-mdx" (click)="getList(item.slNo)" class="btn info"><i class="fa fa-eye"></i></button>
                <button style="margin-left: 5px; margin-top: 2px;" (click)="deleteList(item.slNo)" class="btn danger"><i class="fa fa-trash"></i></button>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>Sl.No</th>
                <th>Client Name</th>
                <th>Phone Number</th>
                <th>Email Id</th>
                <th>Address</th>
                <th>GST No</th>
                <th>Added By</th>
                <th>Action</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- / .modal -->
<div id="m-md" class="modal" data-backdrop="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Clients Invoices</h5>
      </div>
      <div class="modal-body text-center p-lg">
        <div class="row">
          <div class="prod col col-4 col-md-4" *ngFor="let item of invoiceList" (click)="getInvoice(item.slNo)">
            <i class="fa fa-file-text fa-file"></i>
            <br>
            Invoice No: {{item.invoiceNo}}
          </div>
        </div>
      </div>
    </div><!-- /.modal-content -->
  </div>
</div>

<div id="m-mdx" class="modal" data-backdrop="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Clients Invoices</h5>
      </div>
      <div class="modal-body text-center p-lg">
        <div class="row">
          <div class="prod col col-4 col-md-4" *ngFor="let item of invoiceList" (click)="viewInvoice(item.slNo)">
            <i class="fa fa-eye"></i>
            <br>
            Invoice No: {{item.invoiceNo}}
          </div>
        </div>
      </div>
    </div><!-- /.modal-content -->
  </div>
</div>