import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  
  constructor(private http: HttpClient) { }
  urlInitial:string="https://www.growis.co.in/app_admin/";

  postdata(url:any,data:any){
    return this.http.post<any>(url, data);
  }

  getdata(url:any){
    return this.http.get<any>(url);
  } 
}
