<div class="app" id="app">
  <div id="aside" class="app-aside fade nav-dropdown black">
    <app-footer class="navside dk" data-layout="column"></app-footer>
  </div>
  <!-- / -->
  <!-- content -->
  <div id="content" class="app-content box-shadow-z2 bg pjax-container" role="main">
    <div class="app-header white bg b-b">
      <div class="navbar" data-pjax>
        <a data-toggle="modal" data-target="#aside" class="navbar-item pull-left hidden-lg-up p-r m-a-0">
          <i class="ion-navicon"></i>
        </a>
        <div class="navbar-item pull-left h5" id="pageTitle">Admins</div>
      </div>
    </div>
    <div class="app-footer white bg p-a b-t">
      <div class="pull-right text-sm text-muted">Version 1.0.1</div>
      <span class="text-sm text-muted">&copy; Copyright.</span>
    </div>
    <div class="app-body">
      <!-- only need a height for layout 4 -->
      <div class="box container">
        <div class="row">
          <button (click)="modelLaunch()" class="btn btn-fw info" style="margin: 20px;" data-toggle="modal" data-target="#m-md"><i
              class="fa fa-plus" style="padding: 2px 6px;"></i>Add User</button>
        </div>
        <div class="row">
          <table id="example" class="table table-striped table-bordered" style="width:100%">
            <thead>
              <tr>
                <th>Sl.No</th>
                <th>Admin Name</th>
                <th>Username</th>
                <th>User Type</th>
                <th>Email Id</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of adminList;let i = index;">
                <td>{{i+1}}</td>
                <td>{{item.adminName}}</td>
                <td>{{item.username}}</td>
                <td [ngSwitch]="item.type">
                  <p *ngSwitchCase="0">
                    Super Admin
                  </p>
                  <p *ngSwitchCase="1">
                    Admin
                  </p>
                  <p *ngSwitchCase="2">
                    Editor
                  </p>
                  <p *ngSwitchCase="3">
                    Viewer
                  </p>
                  <p *ngSwitchDefault>
                    Please Wait
                  </p>
                </td>
                <td>{{item.email}}</td>
                <td>
                  <button (click)="edit(item.slNo, i)" class="btn success" style="margin-left: 5px;" data-toggle="modal"
                    data-target="#m-md"><i class="fa fa-edit" data-toggle="tooltip" data-placement="top"
                      title="Edit Data"></i></button>
                  <button (click)="changePass(item.type,item.slNo)" class="btn warning" style="margin-left: 5px;" data-toggle="modal" data-target="#m-b"><i
                      class="fa fa-key" data-toggle="tooltip" data-placement="top" title="Change Password"></i></button>
                  <button (click)="delete(item.slNo)" class="btn danger" style="margin-left: 5px;"><i class="fa fa-trash"
                      data-toggle="tooltip" data-placement="top" title="Delete"></i></button>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>Sl.No</th>
                <th>Admin Name</th>
                <th>Username</th>
                <th>User Type</th>
                <th>Email Id</th>
                <th>Actions</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- large modal -->
<div id="m-md" class="modal" data-backdrop="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Add User</h5>
      </div>
      <form [formGroup]="finalData" (ngSubmit)="addAdmin()">
        <div class="modal-body text-center p-lg">
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">Name:</label>
            <div class="col-sm-10">
              <input type="text" class="form-control" formControlName="name">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">Username:</label>
            <div class="col-sm-10">
              <input type="text" class="form-control" (change)="checkUsername()" [(ngModel)]="usrName" formControlName="username">
              <span class="danger" *ngIf="checkUser">Username already exist</span>
            </div>
          </div>
          <div class="form-group row" *ngIf="checkEdit">
            <label class="col-sm-2 form-control-label">Password:</label>
            <div class="col-sm-10">
              <input type="password" class="form-control" [(ngModel)]="password" formControlName="password">
            </div>
          </div>
          <div class="form-group row" *ngIf="checkEdit">
            <label class="col-sm-2 form-control-label">Repeat Password:</label>
            <div class="col-sm-10">
              <input type="password" class="form-control" (change)="checkrepeat()" [(ngModel)]="repeatPass" formControlName="repeatpassword">
            </div>
            <span class="danger" *ngIf="checkRe">Password Doesn't Match</span>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">Email Id:</label>
            <div class="col-sm-10">
              <input type="email" class="form-control" formControlName="email">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">Type of User:</label>
            <div class="col-sm-10">
              <select class="form-control input-c" formControlName="type">
                <option value="0">Super Admin</option>
                <option value="1">Admin</option>
                <option value="2">Editor</option>
                <option value="3">Viewer</option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" #closeadmin class="btn danger p-x-md" data-dismiss="modal">Cancel</button>
          <button type="submit" class="btn success p-x-md">{{buttonValue}}</button>
        </div>
      </form>
    </div><!-- /.modal-content -->
  </div>
</div>
<!-- / .modal -->
<!-- .modal -->
<div id="m-b" class="modal" data-backdrop="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Change Password</h5>
      </div>
      <form [formGroup]="changePassword" (ngSubmit)="postpassChange()">
        <div class="modal-body text-center p-lg">
          <div class="form-group row" *ngIf="checkPass">
            <label class="col-sm-2 form-control-label">Current Password:</label>
            <div class="col-sm-10">
              <input type="password" class="form-control" formControlName="currentPass"> 
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">New Password:</label>
            <div class="col-sm-10">
              <input type="password" class="form-control" [(ngModel)]="password" formControlName="newpass">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 form-control-label">Repeat Password:</label>
            <div class="col-sm-10">
              <input type="password" (change)="checkrepeat()" [(ngModel)]="repeatPass" class="form-control" formControlName="repeatPass">
            </div>
            <span class="danger" *ngIf="checkRe">Password Doesn't Match</span>
          </div>
          <p>Are you sure to execute change password?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn dark-white p-x-md" data-dismiss="modal" #closePass>No</button>
          <button type="submit" class="btn danger p-x-md">Yes</button>
        </div>
      </form>
    </div><!-- /.modal-content -->
  </div>
</div>
<!-- / .modal -->
