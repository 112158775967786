import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators, FormBuilder } from '@angular/forms';
import { NetworkService } from './../network.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})

export class ServicesComponent implements OnInit {
  @ViewChild('closeupload') closeupload:any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  serviceList:any=[];
  imageUrl: string=this.network.urlInitial;
  image: string=this.network.urlInitial;
  imagename:string='Select file ...';
  checkEdit:any;
  checkUpadate:any=0;
  clicked:boolean=false;
  imageSelected:any='0';
  indezEdited:any;
  buttonValue:any="Add service";
  getUrl:string=this.network.urlInitial+"getService.php";
  uploadUrl:string=this.network.urlInitial+"addService.php";
  updateUrl:string=this.network.urlInitial+"updateService.php";
  deleteUrl:string=this.network.urlInitial+"deleteService.php";
  updateFilanUrl:string=this.network.urlInitial+"updateServicesNProduct.php";
  constructor(public fb: FormBuilder, private network: NetworkService) { }
  finalData = this.fb.group({
    id:'', name:'', disc:'', image:'', hsncode:'', tax:'', type:'', price:'', api:'', update:'', fileed:''
  });
  ngOnInit(): void {
    this.getservice();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 8
    }
  }
  addHiddenfields(){
    
  }
  processFile(imageInput:any){
    this.imageSelected='1';
    const reader = new FileReader();
    if(imageInput.target.files && imageInput.target.files.length) {
      const [file] = imageInput.target.files;
      this.imagename=imageInput.target.files[0]['name'];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.image = reader.result as string;
        this.finalData.patchValue({
          image: reader.result
        });
      };
    }
  }
  getservice(){
    this.checkEdit=true;
    this.image='';
    var apiVal = {'api': sessionStorage.getItem('api'), 'type': '0'};    
    this.network.postdata(this.getUrl,apiVal).subscribe(data => {
      this.serviceList = data;
      this.dtTrigger.next();
    })
  }
  selectSave(){
    this.checkUpadate=0;
  }
  saveservice(){
    this.clicked=true;
    if(this.checkUpadate==0){
      if(this.imageSelected=='0'){
        var apiVal = sessionStorage.getItem('api');
        this.finalData.patchValue({
          type: '0',
          fileed: '0',
          api: apiVal
        });
        this.dtTrigger.unsubscribe();
        this.network.postdata(this.uploadUrl,this.finalData.value).subscribe(data => {
          this.clicked=false;
          if(data==true){
            this.closeupload.nativeElement.click();
            this.getservice();
            window.location.reload();
            Swal.fire("Awesome!", "Sucessfully Added!", "success");
          }
        });
      }else{
        this.update();
      } 
    }else{
      var apiVal = sessionStorage.getItem('api');
      if(this.imageSelected==0){
        this.finalData.patchValue({
          api:apiVal,
          fileed: '0'
        });  
      }else{
        this.finalData.patchValue({
          api:apiVal,
          update:'0',
          fileed: '1'
        });  
      }
      this.network.postdata(this.updateFilanUrl,this.finalData.value).subscribe(data => {
        this.clicked=false;
        this.closeupload.nativeElement.click();
        this.getservice();
        window.location.reload();
        Swal.fire("Awesome!", "Sucessfully Updated!", "success");
      });
    }
  }
  edit(slNo:any, i:any){
    this.checkUpadate=1;
    this.checkEdit=true;
    this.image=this.network.urlInitial+this.serviceList[i]['image'];
    this.buttonValue="Update Service";
    var apiVal = sessionStorage.getItem('api');
    this.indezEdited=i;
    this.finalData.patchValue({
      name: this.serviceList[i]['name'],
      disc: this.serviceList[i]['disc'],
      image: this.serviceList[i]['image'],
      hsncode: this.serviceList[i]['hsncode'],
      tax: this.serviceList[i]['tax'],
      update: '1',
      fileed: '1',
      type:'0',
      id: slNo
    });
  }
  update(){    
    var i = this.indezEdited;
    if(this.imageSelected=='0'){
      this.finalData.patchValue({
        name: this.serviceList[i]['name'],
        disc: this.serviceList[i]['disc'],
        image: this.serviceList[i]['image'],
        hsncode: this.serviceList[i]['hsncode'],
        tax: this.serviceList[i]['tax'],
        update: '0',
        fileed: '1'
      });
    }else{
      var apiVal = sessionStorage.getItem('api');
      this.finalData.patchValue({
        update: '1',
        fileed: '1',
        api:apiVal
      });
    }    
    this.network.postdata(this.updateUrl, this.finalData.value).subscribe(data => {      
      this.clicked=false;
      if(data==true){
        this.closeupload.nativeElement.click();
        this.getservice();
        window.location.reload();
        Swal.fire("Awesome!", "Sucessfully Added!", "success");
      }
    });
  }
  delete(i:any){
    Swal.fire({
      title: 'Are you sure?',text: "You won't be able to revert this!",icon: 'warning',
      showCancelButton: true,confirmButtonColor: '#3085d6',cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        var apiVal = {'api': sessionStorage.getItem('api'), 'id':i};
        this.network.postdata(this.deleteUrl,apiVal).subscribe(data => {
          if(data==false){
            Swal.fire('Sorry!','You dont have enough privilage','error')
          }
          else{
            Swal.fire('Deleted!','Your file has been deleted.','success')
          }
          this.getservice();
          window.location.reload();
        })
      }
    })
  }
}

