import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators, FormBuilder } from '@angular/forms';
import { NetworkService } from './../network.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  errorMessage:boolean=false;
  message:string="Wrong Credentials";
  messageColour:string="";
  loginData = this.fb.group({
    username: '',
    password: '',
    session: ''
  });
  loginUrl:string=this.network.urlInitial+"login.php";

  constructor(public fb: FormBuilder, private network: NetworkService, private router: Router) { 
  }

  ngOnInit(): void {    
    if(localStorage.getItem("isLogin")!=null){
      this.router.navigate(['create']);
    }
  }

  login(): void{
    var smallNow = (new Date().getTime()+99999999);
    var largeNow = (new Date().getTime()+55386270391);    
    this.errorMessage=false;
    this.network.postdata(this.loginUrl,this.loginData.value).subscribe(data => {
      if(data=="x00105" ){
        this.errorMessage=true;
        this.messageColour="primary md";
        this.message = "Please Enter Your Credentials";
      }
      else if(data=="x00106"){
        this.errorMessage=true;
        this.messageColour="danger md";
        this.message = "Wrong Credentials !!";
      }else{
        this.router.navigate(['create']);
        sessionStorage.setItem('api',data['session_id']);
        sessionStorage.setItem('email',data['email']);
        localStorage.setItem("isLogin",'true');
        if(data['session_expiry']=="false"){
          sessionStorage.setItem('expiry', smallNow.toString());
        }else{
          sessionStorage.setItem('expiry', largeNow.toString());
        }
      }
    }
  )};

}
