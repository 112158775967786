<div class="app" id="app">    
    <div id="aside" class="app-aside fade nav-dropdown black">
      <app-footer class="navside dk" data-layout="column"></app-footer>
    </div>
    <!-- / -->
    <!-- content -->
    <div id="content" class="app-content box-shadow-z2 bg pjax-container" role="main">
      <div class="app-footer white bg p-a b-t">
        <div class="pull-right text-sm text-muted">Version 1.0.1</div>
        <span class="text-sm text-muted">&copy; Copyright.</span>
      </div>
      <div class="app-body">
  <!-- only need a height for layout 4 -->
          <div style="min-height: 200px"></div>
      </div>
  </div>    
</div>