import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NetworkService } from './../network.service';
import { LoginServiceService } from './../login-service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  logoutUrl:string=this.network.urlInitial+"logout.php";
  getUrl:string=this.network.urlInitial+"getmyUname.php";
  checkDriveUrl:string=this.network.urlInitial+"google_drive/remoteAuth.php";
  name="";
  smallUname="";

  constructor(private network: NetworkService, private router: Router, private loginService:LoginServiceService) { }

  ngOnInit(): void {
    this.getData();
    this.checkDrive()
  }
  
  checkDrive(){
    this.network.getdata(this.checkDriveUrl).subscribe(data => {
      if(data.value==true){
        Swal.fire({
          title: 'Sorry Your Google Drive Account Got Logged Out?',
          text: "Please Connect!",
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sign In'
        }).then((result) => {
          if (result.isConfirmed) {
            window.open(data.url);
            
          }
        })
     
      }
    })
  }

  getData():void{
    var apiVal = {'api': sessionStorage.getItem('api')};    
    this.network.postdata(this.getUrl,apiVal).subscribe(data => {
      this.name = data.toUpperCase();
      this.smallUname = this.name.substring(0,2)      
    })
  }
  logout():void{
    this.network.postdata(this.logoutUrl,this.loginService.logoutSession()).subscribe(data => {
    });
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['']);
  }
}
