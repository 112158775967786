import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {
   }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {      
      if(sessionStorage.getItem("api")==null){
        localStorage.removeItem('isLogin')
        this.router.navigate(['']);
      }  
      const isLogin=localStorage.getItem('isLogin');
      let currentexpiry = isLogin !== null ? JSON.parse(isLogin) : 'false';
      if(isLogin?.toString()=="true"){  
        return true;
      }else{
        return false;
      }
  }
  
}
