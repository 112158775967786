import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators, FormBuilder } from '@angular/forms';
import { NetworkService } from './../network.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  @ViewChild('closeupload') closeupload:any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  productList:any=[];
  imageUrl: string=this.network.urlInitial;
  image: string=this.network.urlInitial;
  imagename:string='Select file ...';
  checkEdit:any;
  checkUpadate:any=0;
  clicked:boolean=false;
  imageSelected:any='0';
  indezEdited:any;
  buttonValue:any="Add Product";
  getUrl:string=this.network.urlInitial+"getService.php";
  uploadUrl:string=this.network.urlInitial+"addService.php";
  updateUrl:string=this.network.urlInitial+"updateService.php";
  deleteUrl:string=this.network.urlInitial+"deleteService.php";
  updateFilanUrl:string=this.network.urlInitial+"updateServicesNProduct.php";
  constructor(public fb: FormBuilder, private network: NetworkService) { }
  finalData = this.fb.group({
    id:'', name:'', disc:'', image:'', hsncode:'', tax:'', type:'', price:'', api:'', update:'', fileed:''
  });
  ngOnInit(): void {
    this.getProducts();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 8
    };

  }
  processFile(imageInput:any){
    this.imageSelected='1';
    const reader = new FileReader();
    if(imageInput.target.files && imageInput.target.files.length) {
      const [file] = imageInput.target.files;
      this.imagename=imageInput.target.files[0]['name'];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.image = reader.result as string;
        this.finalData.patchValue({
          image: reader.result
        });
      };
    }
  }
  getProducts(){
    this.checkEdit=true;
    this.image='';
    var apiVal = {'api': sessionStorage.getItem('api'), 'type': '1'};    
    this.network.postdata(this.getUrl,apiVal).subscribe(data => {
      this.productList = data;
      this.dtTrigger.next();
    })
  }
  selectSave(){
    this.checkUpadate=0;
  }
  saveProduct(){ 
    this.clicked=true;
    if(this.checkUpadate==0){
      if(this.imageSelected=='0'){
        var apiVal = sessionStorage.getItem('api');
        this.finalData.patchValue({
          type: '1',
          fileed: '0',
          api: apiVal
        });
        this.dtTrigger.unsubscribe();
        this.network.postdata(this.uploadUrl,this.finalData.value).subscribe(data => {
          if(data==true){
            this.closeupload.nativeElement.click();
            this.getProducts();
            window.location.reload();
            Swal.fire("Awesome!", "Sucessfully Added!", "success");
          }
        });
      }else{
        this.update();
      }
    }else{
      var apiVal = sessionStorage.getItem('api');
      if(this.imageSelected==0){
        this.finalData.patchValue({
          api:apiVal,
          fileed: '0',
          type: '1',
        });  
      }else{
        this.finalData.patchValue({
          api:apiVal,
          update:'0',
          fileed: '1',
          type: '1',
        });  
      }      
      this.network.postdata(this.updateFilanUrl,this.finalData.value).subscribe(data => {
        this.clicked=false;
        this.closeupload.nativeElement.click();
        this.getProducts();
        window.location.reload();
        Swal.fire("Awesome!", "Sucessfully Updated!", "success");
      });

    }
  }
  edit(slNo:any, i:any){
    this.checkUpadate=1;
    this.checkEdit=true;
    this.image=this.network.urlInitial+this.productList[i]['image'];
    this.buttonValue="Update Product";
    var apiVal = sessionStorage.getItem('api');
    this.indezEdited=i;
    this.finalData.patchValue({
      name: this.productList[i]['name'],
      disc: this.productList[i]['disc'],
      image: this.productList[i]['image'],
      hsncode: this.productList[i]['hsncode'],
      tax: this.productList[i]['tax'],
      price: this.productList[i]['price'],
      update: '1',
      fileed: '1',
      type:'1',
      id: slNo
    });
  }
  update(){    
    var i = this.indezEdited;
    if(this.imageSelected=='0'){
      this.finalData.patchValue({
        name: this.productList[i]['name'],
        disc: this.productList[i]['disc'],
        image: this.productList[i]['image'],
        hsncode: this.productList[i]['hsncode'],
        tax: this.productList[i]['tax'],
        price: this.productList[i]['price'],
        update: '1',
        type: '1',
        fileed: '1'
      });
    }else{
      var apiVal = sessionStorage.getItem('api');
      this.finalData.patchValue({
        update: '1',
        type: '1',
        api:apiVal,
        fileed: '1'
      });
    }    
    this.network.postdata(this.updateUrl, this.finalData.value).subscribe(data => {
      this.clicked=false;
      if(data==true){
        this.closeupload.nativeElement.click();
        this.getProducts();
        window.location.reload();
        Swal.fire("Awesome!", "Sucessfully Added!", "success");
      }
    });
  }
  delete(i:any){
    Swal.fire({
      title: 'Are you sure?',text: "You won't be able to revert this!",icon: 'warning',
      showCancelButton: true,confirmButtonColor: '#3085d6',cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        var apiVal = {'api': sessionStorage.getItem('api'), 'id':i};
        this.network.postdata(this.deleteUrl,apiVal).subscribe(data => {
          if(data==false){
            Swal.fire('Sorry!','You dont have enough privilage','error')
          }
          else{
            Swal.fire('Deleted!','Your file has been deleted.','success')
          }
          this.getProducts();
          window.location.reload();
        })
      }
    })
  }
}
